<template>
  <div>
    <div class="is-flex is-justify-content-space-between mb-5">
      <p class="is-size-4 is-size-3-desktop has-text-weight-bold">
        {{ $t("af:purchase.history.title.purchase_history") }}
      </p>
      <b-field>
        <b-input
          v-model="stringToSearch"
          class="purchase-history__searcher"
          size="is-medium"
          :placeholder="`${$t('af:purchase.history.search')}...`"
          type="search"
          icon-pack="fa"
          icon="search"
        />
      </b-field>
    </div>
    <p v-if="stringToSearch" class="has-text-centered is-size-4">
      {{ `${$t("af:purchase.history.searched_string")}: ${stringToSearch}` }}
    </p>
    <div class="purchase-history__group-list">
      <offer-loading v-if="isLoading" />
      <template v-else-if="canDisplayPurchases">
        <div
          v-for="(purchases, date) in purchasesComp"
          :key="date"
          class="purchase-history__item-list"
        >
          <h3>{{ date }}</h3>
          <purchase-card
            v-for="purchase in purchases"
            :key="purchase.id"
            :purchase="purchase"
            @viewDetails="routeToPurchasePage(purchase.id)"
          />
        </div>
      </template>
      <p v-else class="has-text-centered is-size-5">
        {{ $t("af:purchase.history.no_data") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _debounce from "lodash.debounce";
import _isEmpty from "lodash.isempty";
import dayjs from "dayjs";

export default {
  name: "UserPurchaseHistory",
  title: "af:purchase.history.page_title",
  components: {
    OfferLoading: () => import("@/components/offers/OfferLoading.vue"),
    PurchaseCard: () => import("@/components/offers/PurchaseCard.vue"),
  },
  async mounted() {
    await this.$store.dispatch("purchase/fetchMyPurchases");
    this.searchForPurchases = _debounce(function () {
      this.actualStringToSearch = this.stringToSearch.toLowerCase();
      this.isLoading = false;
    }, 450);
    this.isLoading = false;
  },
  methods: {
    async routeToPurchasePage(purchaseId) {
      this.$router.push({
        name: "Purchase",
        params: {
          purchaseId,
        },
      });
    },
    containsSearchedString(purchase) {
      if (!this.actualStringToSearch.length) return true;
      const propertyBlackList = ["merchantLogo", "id"];
      let containsStr;
      for (const key of Object.keys(purchase)) {
        if (propertyBlackList.includes(key)) {
          continue;
        }
        if (
          purchase[key]
            .toString()
            .toLowerCase()
            .includes(this.actualStringToSearch)
        ) {
          containsStr = true;
          break;
        }
      }
      return containsStr;
    },
  },
  computed: {
    ...mapGetters({
      purchases: "purchase/getMyPurchases",
    }),
    stringToSearch: {
      get: function () {
        return this.stringToSearchLazy;
      },
      set: function (val) {
        if (val.length < 100) {
          this.stringToSearchLazy = val;
          this.isLoading = true;
          this.searchForPurchases();
        }
      },
    },
    purchasesComp() {
      if (!this.purchases.length) return {};
      const purchaseMap = {};
      [...this.purchases]
        .sort((a, b) =>
          dayjs(a.purchaseDatetime).isBefore(dayjs(b.purchaseDatetime)) ? 1 : -1
        )
        .forEach((purchase) => {
          if (this.containsSearchedString(purchase)) {
            const key = dayjs(purchase.purchaseDatetime).format(
              "YYYY. MM. DD."
            );
            if (purchaseMap[key]) {
              purchaseMap[key].push(purchase);
            } else {
              purchaseMap[key] = [purchase];
            }
          }
        });
      return purchaseMap;
    },
    canDisplayPurchases() {
      return !_isEmpty(this.purchasesComp);
    },
  },
  data() {
    return {
      isLoading: true,
      searchForPurchases: null,
      stringToSearchLazy: "",
      actualStringToSearch: "",
    };
  },
};
</script>

<style lang="scss">
.purchase-history__searcher {
  .svg-inline--fa {
    width: 1.5rem;
  }
}
.purchase-history__group-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .purchase-history__item-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
